import React from "react";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { Box, Button, IconButton, Collapse } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MuiLink from "@material-ui/core/Link";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import HorizontalLogo from "../images/v2/campaigns/Horizontal-logo.png";
import Logo from "../images/v2/PC-vertical-logo.svg";
import callImg from "../images/v2/campaigns/call.svg";
import Divider from "@material-ui/core/Divider";
import singaporeSmeBannerImg from "../images/v2/campaigns/dashboardBackgroundImg.png";
import singaporeSmeSalesCRMImg from "../images/v2/campaigns/singapore-sme-sales-crm-img-v3.png";
import useWidth from "../hooks/useWidth";
import requestDemoBGImg from "../images/v2/campaigns/request-demo-bg.svg";
import loadable from "@loadable/component";
import WhiteButton from "../components/v2/WhightButton";
import IntegratedCRMBgImg from "../images/v2/home/integrated-crm-bg.svg";
import singaporeSmeTestimonialImg from "../images/v2/campaigns/Home page creative  v3 (2).png";
import faqbackgroundImg from "../images/v2/campaigns/background.png";
import { conversionEventOnCall } from "./singapore-sme-v2";
import SEO from "../components/SEO";
import card1Img from "../images/v2/campaigns/card1media.png";
import card2Img from "../images/v2/campaigns/whatsappCard2Img.png";
import card3Img from "../images/v2/campaigns/whatsappCard3Img.png";
import whatsappBackgroundImg from "../images/v2/campaigns/whatsappBackgroundImg.png";
import whatsapptextImg1 from "../images/v2/campaigns/whatappTextImg1.png";
import whatsapptextImg2 from "../images/v2/campaigns/whatappTextImg2.png";
import whatsapptextImg3 from "../images/v2/campaigns/whatappTextImg3.png";
import whatsappTitleImage from "../images/v2/campaigns/whatsappTitleImage.png";
import reactangelImage from "../images/v2/campaigns/reactangleCornerImage.png";
import yellowLine from "../images/v2/campaigns/yellowLine.webp";
import smeSupportLogo from "../images/v2/campaigns/smeSupportLogo.png";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
  faqbackgroundDivder: {
    marginTop: "144px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "15px",
    },
  },
  pepperCloudCRMHelps: {
    [theme.breakpoints.up("md")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
    },
  },
  marginAuto: {
    margin: "0 auto",
  },
  header: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.29)",
    padding: ".65rem 0 .25rem",
  },
  backgroundWhite: {
    position: `relative`,
    backgroundColor: `#fff`,
  },
  faqbackground: {
    position: `relative`,
    background: `#fff url(${faqbackgroundImg}) no-repeat center`,
    backgroundSize: "cover",
    margin: "79.5px 0 ",
  },
  whatsappBackground: {
    position: "relative",
    background: `#fff url(${whatsappBackgroundImg}) no-repeat center`,
    width: "100%",
    backgroundSize: "cover",
  },
  singaporeSmeBanner: {
    position: `relative`,
    background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
    [theme.breakpoints.up(1600)]: {
      position: `relative`,
      background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
      backgroundSize: "cover",
      width: "100%",
    },
  },
  singaporeSmeBannerRightImg: {
    position: `absolute`,
    backgroundSize: "contain",
    paddingTop: "50px",
    right: `-25%`,
    backgroundPosition: `right`,
    width: `100%`,
    height: `100%`,
    top: `25%`,
    [theme.breakpoints.down(1120)]: {
      display: `none`,
    },
    [theme.breakpoints.up(1300)]: {
      right: `-20%`,
    },
    [theme.breakpoints.up(1600)]: {
      right: `-15%`,
    },
    [theme.breakpoints.up(1900)]: {
      right: `0%`,
    },
  },
  singaporeSmeFromBox: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "0",
    },
    [theme.breakpoints.up("xs")]: {
      paddingTop: "2rem",
    },
  },
  listItem: {
    paddingLeft: "0px",
  },
  listItemText: {
    "& *": {
      fontSize: 18,
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 26,
    backgroundColor: "#15a323",
    borderRadius: "50%",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  },
  singaporeSmeFrom: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 2px 5px 0 #b7b7b7",
    backgroundSize: "430px 522px",
    position: "relative",
    zIndex: "1",
    margin: "0 auto",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  smeSupportedByLogo: {
    [theme.breakpoints.down("xs")]: {},
  },
  singaporeSmeFromHeader: {
    background: "#2e3f4f",
    borderRadius: "10px 10px 0 0",
    padding: ".6rem .25rem",
    letterSpacing: ".32px",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  salesProcessCard: {
    border: "none",
    boxShadow: "none",
    marginBottom: theme.spacing(4),
  },
  cardHeaderAvatar: {
    height: 100,
    width: 100,
    background: "#FFF",
    boxShadow: "1px 1px 7px 0 rgb(0 0 0 / 19%)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -68,
    [theme.breakpoints.down("sm")]: {
      height: 60,
      width: 60,
    },
  },
  img: {
    backgroundSize: "contain",
  },
  nopad: {
    paddingLeft: "0",
    paddingTop: "0",
  },
  nopadtpbtm: {
    paddingTop: "0",
    paddingBottom: "0",
    "& svg": {
      margin: 0,
    },
  },
  listWidth: {
    width: "50%",
  },
  colorChange: {
    color: "#ff7a59",
    fontSize: "30px",
    fontWeight: "700",
    letterSpacing: ".4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  whatsappContainerMargin: {
    marginLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
    },
  },
  singaporeSmeSalesCRMImg: {
    marginTop: "-70px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
    },
  },
  cardHeader: {
    background: "#f2f7fd",
    marginLeft: 54,
  },
  landingbtn: {
    padding: "10px 45px",
    fontSize: "22px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      padding: "10px 35px",
      fontSize: "18px",
    },
  },
  IntegratedCRMBg: {
    background: `url(${IntegratedCRMBgImg}) center bottom no-repeat`,
    backgroundSize: "cover",
  },
  whitespaceNowrap: {
    whiteSpace: "nowrap",
  },
  pepperCloudCRMHelps: {
    [theme.breakpoints.up("md")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
    },
  },
  pepperCloudCRMHelpsSpan: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#ffffff",
      padding: "0 1rem",
    },
  },
  pepperCloudCRMHelpsContent: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#fff7e0",
    padding: "20px 0 20px 0",
  },
  pepperCloudCRMHelpsContentListBox: {
    padding: "0rem 1rem",
  },
  pepperCloudCRMHelpsContentList: {
    listStyle: "none",
    padding: "0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  pepperCloudCRMHelpsContentListItem: {
    display: "inline-block",
    paddingRight: "2rem",
    marginRight: "2rem",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none !important",
    },
  },
  copyFooter: {
    color: "#cbcccd",
    textAlign: "end",
    paddingRight: "23px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  yellowImageLine: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  pepperCloudCRMHelpsContentListItemSpan: {
    color: "#e2ab10",
    fontSize: "61px",
    fontWeight: "600",
    lineHeight: "normal",
    fontFamily: "Poppins",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  faqCard: {
    borderRadius: "20px",
    boxShadow: "1px 1px 10px 0 rgba(0, 0, 0, 0.16)",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  ConnectCrm: {
    background: "#ebf9fc",
    padding: "20px",
  },
  PCBenefits: {
    background: "#ebf9fc",
  },
  smeBannerTxthead: {
    padding: "3px",
    margin: "0",
    marginLeft: "5px",
  },
  martop: {
    marginTop: "10px",
  },
  whatsapptextImg3: {
    position: "absolute",
    right: "23px",
    top: "485px",
    [theme.breakpoints.down("md")]: {
      left: "131px",
      top: "488px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "447px",
      left: "61px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "25px",
      top: "425px",
    },
  },
  whatsapptextImg2: {
    position: "absolute",
    right: "37px",
    top: "490px",
    [theme.breakpoints.down("sm")]: {
      top: "490px",
      right: "204px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "455px",
      right: "24px",
    },
  },
  whatsapptextImg1: {
    position: "absolute",
    left: "131px",
    top: "488px",
    [theme.breakpoints.down("md")]: {
      left: "131px",
      top: "488px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "505px",
      left: "119px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "31px",
      top: "461px",
    },
  },
  rectangelImageContainer: {
    position: "absolute",
    right: "-15px",
    top: "99px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  whatsappDisclaimer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "24px",
    paddingRight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      paddingRight: "0",
    },
  },
  reactangelImageContainer1: {
    position: "absolute",
    right: "-18px",
    top: "99px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  yellowContiner: {
    border: "5px solid #e2ab10",
    right: "348px",
    bottom: "-13px",
    borderRadius: "13px",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  whatsappTitleImageContainer: {
    position: "absolute",
    top: "60px",
    paddingLeft: "6rem",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "6rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  faqbackgroundDivder: {
    //  marginTop: "144px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "15px",
    },
  },
  smeSupportLogo: {
    width: "100%",
    padding: "0 17px",
    [theme.breakpoints.down("xs", "sm")]: {
      width: "100%",
      padding: "0 ",
    },
  },
  relativeContainer: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
    },
  },
  relativeContainerSide: {
    position: "relative",
    marginTop: "120px",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
  },
  relativeContainer2: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
  },
  cardindexBackground: {
    boxShadow: "none",
    borderRadius: "12px",
    border: "solid 1px #0893af",
  },
  cardImg: {
    width: "100%",
    height: "auto",
    transform: "scale(1.1)",
  },
  linkHeader: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  supportedByLogo: {
    marginBottom: "22px",
    color: "#2e3f4f",
    fontSize: "13px",
    fontWeight: "700",
    letterSpacing: "-.5px",
    marginTop: 0,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "12px",
      fontSize: "16px",
      marginTop: "9px",
    },
  },
  listHeading: {
    paddingLeft: "228px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
  },
  pcWebForm: {
    width: "100%",
    marginTop: "-7px",
    height: "600px",
    [theme.breakpoints.down("sm")]: {
      height: "600px",
    },
  },
  webformContainer: {
    height: "600px",
    [theme.breakpoints.down("sm")]: {
      height: "600px",
    },
  },
  accordSection: {
    marginTop: "5rem",
    "@media(max-width:960px)": {
      paddingTop: "0",
    },
  },
  accordionTitleConvert: {
    color: "#000",
    marginTop: "-150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      textAlign: "center",
      marginBottom: "12px",
      marginTop: "50px",
    },
    "@media(max-width:1920px)": {
      marginTop: "-50px",
    },
    "@media(min-width:1921px)": {
      marginTop: "-30px",
    },
    "@media(min-width:960px)": {
      marginLeft: "-125px",
    },
  },
  accordionTitleWhatsapp: {
    color: "#000",
    marginTop: "-50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      textAlign: "center",
      marginBottom: "12px",
      marginTop: "0px",
    },
    "@media(min-width:1920px)": {
      marginTop: "-50px",
    },
    "@media(min-width:1921px)": {
      marginTop: "50px",
    },
  },
  mobilePad: {
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 24,
    backgroundColor: "#08a742",
    borderRadius: "50%",
    padding: "5px",
    marginTop: "10px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  listItemText: {
    cursor: "pointer",
    "& *": {
      fontSize: 20,
      lineHeight: "30px",
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  customButtonResponssive: {
    "@media(max-width:960px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  customButton: {
    fontSize: "22px",
    marginTop: "35px",
  },
  buildExperiencesImg: {
    maxWidth: "80%",
    height: "80%",
    marginLeft: "4rem",
    position: "relative",
    top: "-75px",
    "@media(max-width:960px)": {
      top: "0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
    [theme.breakpoints.up("xl")]: {
      textAlign: "left!important",
    },
  },
  img: {
    maxWidth: "100%",
    height: "auto",
    willChange: "auto !important",
  },
  list: {
    paddingLeft: "8px",
    alignItems: "flex-start",
    "&.selected ": {
      backgroundColor: "#e3f7f8",
      borderRadius: "4px",
      "@media(max-width:960px)": {
        backgroundColor: "transparent",
      },
    },
  },
}));


const listOne = `Best sales automation tool
AI-powered CRM software`.split(`
`);

const listTwo = `Built for SMEs
24*7 local support`.split(`
`);

const listThree = `Simplify communication with omnichannel CRM.
Build custom sales pipeline.
Amplify sales with WhatsApp CRM tool.`.split(`
`);

const listFour = `Streamline tasks with business workflow automation.
Instantly resolve queries with AI chatbot.
Track sales with an intuitive dashboard.`.split(`
`);

const CustomExperience =
  `Automatically capture leads, send greeting messages, respond to queries with AI chatbot, and streamline business with sales and marketing automation.
  Integrated with various tools like WhatsApp, Xero, Aircall, Zoom, Mailchimp, and more and access client details and conversation history through a unified platform.
  Get 24/7 local support for all your queries, including CRM demos, technical support, product customisation, and pricing plans.
  Experience user-friendly and affordable CRM software tailored for small businesses and medium businesses.
  Maximise sales, automate marketing, and provide round-the-clock support with the best sales CRM software.`
    .split(`
  `);

const CompanyCarousel = loadable(() =>
  import("../components/v2/CompanyCarousel")
);
const GoogleAdV3 = () => {
  const myRef = React.useRef(null);

  const executeScroll = () => {
    myRef.current.scrollIntoView();
  };

  const classes = useStyles();

  const width = useWidth();
  const [activeCalendar, setActiveCalendar] = React.useState(0);
  const salesCRMSolution = [
    {
      title: "What is customer relationship management (CRM) software?",
      description: (
        <>
          CRM software, short for customer relationship management, effectively
          tracks your business's leads, potential clients, and existing
          customers. It captures crucial details and interactions, enabling you
          to oversee their journey throughout the sales cycle. Additionally, CRM
          facilitates seamless integration between various tools and platforms.
          Various departments within your organisation, including sales,
          marketing, and customer service, benefit from access to unified
          information, enhancing efficiency and message consistency across the
          company. Furthermore, robust CRM solutions like{" "}
          <a href="https://peppercloud.com/" style={{ color: "#198cb5" }}>
            Pepper Cloud CRM{" "}
          </a>
          provide management with valuable insights into sales and marketing
          performance, empowering informed decision-making.
        </>
      ),
    },
    {
      title: "How does Pepper Cloud CRM software work?",
      description:
        "Pepper Cloud CRM, the best CRM in Guatemala, manages customer interactions by compiling them into a centralised platform accessible to all team members. Users gain full or selective access to a comprehensive customer journey history, facilitating workflow optimisation. Additionally, you gain insights into prospects' progress through the sales cycle, enhancing conversion effectiveness.",
    },
    {
      title: "Why is it required to use a CRM software for businesses?",
      description:
        "Your company's connection with customers is crucial, guiding their journey and ensuring each interaction is meaningful. Efficient CRM solutions centralise vital data, empowering staff to swiftly access information and devote more time to nurturing positive relationships. With unified knowledge, your business can prioritise delivering a consistent and personalised customer experience.",
    },
    {
      title:
        "Is Pepper Cloud CRM built for small and medium businesses (SMEs)?",
      description: (
        <>
          Yes,{" "}
          <a href="https://peppercloud.com/" style={{ color: "#198cb5" }}>
            Pepper Cloud CRM{" "}
          </a>
          is specifically designed for SMEs with features that cater to your
          needs and budget.
        </>
      ),
    },
    {
      title:
        "What are the benefits of a customer relationship management (CRM) platform?",
      description: (
        <>
          A CRM platform boosts sales efficiency, enhances customer
          satisfaction, streamlines communication, delivers valuable insights,
          and drives business growth. Read more about the{" "}
          <a
            href="https://blog.peppercloud.com/10-benefits-of-using-whatsapp-crm-for-smes/"
            style={{ color: "#198cb5" }}
          >
            benefits of a CRM system.
          </a>
        </>
      ),
    },
    {
      title:
        "Can I integrate Pepper Cloud sales CRM with my existing tools and platforms?",
      description: (
        <>
          Yes, Pepper Cloud CRM seamlessly integrates with numerous popular
          business tools and platforms. It works with WhatsApp, Facebook
          Messenger, Zoom, Xero, Mailerlite, Mailchimp, Aircall, and many more,
          enabling you to{" "}
          <a
            href="https://blog.peppercloud.com/crm-workflow-automation/"
            style={{ color: "#198cb5" }}
          >
            streamline your workflow{" "}
          </a>
          and centralise your data.
        </>
      ),
    },
    {
      title: "Does Pepper Cloud offer customer support?",
      description: (
        <>
          Yes, Pepper Cloud provides 24/7 local support from experienced CRM
          consultants in Guatemala to ensure you get the most out of your CRM
          system.
        </>
      ),
    },
    {
      title: " How much does Pepper Cloud CRM cost?",
      description: (
        <>
          We offer a variety of pricing plans to fit your specific business
          needs, which makes Pepper Cloud CRM an affordable sales CRM software
          for small businesses. Contact us for a customised quote.
        </>
      ),
    },
    {
      title:
        "How long does it take to set up Pepper Cloud CRM system for my small business in Guatemala?",
      description: (
        <>
          With all essential prerequisites fulfilled, setting up Pepper Cloud
          CRM for your small business in Guatemala can be completed within a few
          hours.
        </>
      ),
    },
    {
      title:
        "Is it difficult to use Pepper Cloud - #1 CRM in Guatemala? Does my team require any prior technical knowledge?",
      description: (
        <>
          Pepper Cloud is a user-friendly CRM suitable for any growing business,
          requiring no prior technical expertise. Additionally, we offer
          training sessions to all clients and provide round-the-clock local
          support.
        </>
      ),
    },
  ];

  React.useEffect(() => {
    const pcIframe = document.getElementById("pc-iframe");
    pcIframe.src = pcIframe.src + window.location.search;
    if (window.location.search)
      pcIframe.src = pcIframe.src + "&page_title=" + document.title;
    else pcIframe.src = pcIframe.src + "?page_title=" + document.title;
  }, []);

  return (
    <React.Fragment>
      <SEO
        canonical="/best-sales-crm-software-guatemala-for-small-business"
        description=" Guatemala's #1 sales CRM software designed just for your small business with features like sales & marketing automation, lead capturing, powerful CRM integration & more."
        keywords="crm guatemala,crm software for small business,best crm software,sales crm software"
        pathname="/best-sales-crm-software-guatemala-for-small-business"
        title="The Best Sales CRM Software for Small Businesses in Guatemala"
      />
      <Box className={classes.header}>
        <Box component={Container}>
          <Grid alignItems="center" container justifyContent="center">
            <Grid item md={7} sm={12}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="flex-start"
                my={1}
              >
                <Box alignItems="center" display="flex">
                  <Link to="/">
                    <Box
                      alt="Best CRM Software in Guatemala"
                      component={"img"}
                      height={{ sm: 36, xs: 36 }[width] || 58}
                      mr={{ sm: 1, xs: 1 }[width] || 2}
                      src={HorizontalLogo}
                    />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item md={5} sm={12}>
              <Box
                alignItems="flex-end"
                display="flex"
                justifyContent="flex-end"
                my={1}
                textAlign="right"
              >
                <HeaderTypography
                  className="header"
                  color="#2e3f4f"
                  fontSize={{ sm: 13, xs: 13 }[width] || 18}
                  fontWeight={600}
                  m={0}
                  mr={{ sm: 2, xs: 1 }[width] || 2}
                  overrideClassName
                  component="h4"
                >
                  Book a free demo
                </HeaderTypography>
                <MuiLink href="tel:+6597510475" onClick={conversionEventOnCall}>
                  <Box alignItems="center" display="flex">
                    <Box
                      alt="Call to Pepper Cloud"
                      component={"img"}
                      height={{ sm: 14, xs: 14 }[width] || 20}
                      mr={{ sm: 0, xs: 0 }[width] || 1}
                      src={callImg}
                    />
                    <ParagraphTypography
                      className={classes.whitespaceNowrap}
                      color="#0291ae"
                      fontSize={{ sm: 14, xs: 14 }[width] || 20}
                      fontWeight={700}
                      m={0}
                      overrideClassName
                    >
                      +502 5712-2606
                    </ParagraphTypography>
                  </Box>
                </MuiLink>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Banner */}
      <Box className={classes.singaporeSmeBanner}>
        <Container>
          <Grid alignItems="flex-start" container justify="center" mt={3}>
            <Grid item md={7} sm={12}>
              <Box>
                <HeaderTypography
                  fontSize={{ sm: 36, xs: 34 }[width] || 42}
                  fontWeight={600}
                  lineHeight={1.25}
                  mb={4}
                  mt={3}
                  overrideClassName
                  component="h1"
                >
                  Close more deals with the <br />{" "}
                  <span style={{ color: "#15a323" }}>
                    best CRM in Guatemala
                  </span>{" "}
                </HeaderTypography>
                <ParagraphTypography
                  className={classes.smeBannerTxt}
                  color="#2e3f4f"
                  component="p"
                  font-family="SourceSansPro"
                  fontSize={18}
                  mb={3}
                  mt={0}
                >
                  Elevate your sales performance with the best CRM in Guatemala
                  for small and medium-sized businesses. Simplify sales
                  management, win more opportunities, and streamline customer
                  communication effortlessly.
                </ParagraphTypography>
                <Box pr={{ sm: "0px", xs: "0px" }[width] || "3rem"}>
                  <Grid
                    alignItems="center"
                    container
                    justifyContent="start"
                    sm={12}
                    xs={12}
                  >
                    <Grid item md={6} sm={12}>
                      <Box>
                        <List className={classes.nopadtpbtm} pl={0}>
                          {listOne.map((each) => (
                            <ListItem
                              alignItems="center"
                              className={classes.nopad}
                              key={each}
                            >
                              <ListItemIcon>
                                <CheckIcon className={classes.listItemIcon} />
                              </ListItemIcon>
                              <ListItemText className={classes.listItemText}>
                                {each}
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Box>
                        <List className={classes.nopadtpbtm} pl={0}>
                          {listTwo.map((each) => (
                            <ListItem
                              alignItems="center"
                              className={classes.nopad}
                              key={each}
                            >
                              <ListItemIcon>
                                <CheckIcon className={classes.listItemIcon} />
                              </ListItemIcon>
                              <ListItemText className={classes.listItemText}>
                                {each}
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <br />
                <Box display={{ sm: "block", xs: "block" }[width] || "flex"}>
                  <Box
                    alt="best WhatsApp CRM in Guatemala"
                    component={"img"}
                    maxHeight="450px"
                    maxWidth="100%"
                    src={singaporeSmeTestimonialImg}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={5} sm={9} xs={12}>
              <Box
                className={classes.singaporeSmeFromBox}
                px={1}
                textAlign="center"
              >
                {/* <Box
                  alt="SG Digital"
                  component={"img"}
                  src={smeSupportLogo}
                  className={classes.smeSupportLogo}
                /> */}

                <Box className={classes.singaporeSmeFrom} ref={myRef}>
                  <HeaderTypography
                    className={classes.singaporeSmeFromHeader}
                    color="#fff"
                    component="h3"
                    fontWeight={600}
                    m={0}
                    textAlign="center"
                  >
                    Contact Us
                  </HeaderTypography>
                  <Box className={classes.webformContainer}>
                    <Box
                      className={classes.pcWebForm}
                      border="none"
                      component="iframe"
                      id="pc-iframe"
                      overflow="hidden"
                      referrerpolicy="unsafe-url"
                      src="https://app.peppercloud.com/form/70378dc7-f884-424c-979f-7e2757868b26/embed"
                      width="99%"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Content*/}
      <Box
        pb={2}
        pt={{ sm: 12, xs: 12 }[width] || 12}
        textAlign="center"
        className={classes.backgroundWhite}
      >
        <Container>
          <Box className={classes.listHeading}>
            <HeaderTypography
              component="h2"
              fontSize={35}
              fontWeight={600}
              maxWidth={725}
              alignItems="center"
              mb={3}
              mt={0}
              textAlign="center"
            >
              Boost sales productivity with the top{" "}
              <span style={{ color: "#15a323" }}>sales CRM system</span>
            </HeaderTypography>
          </Box>
          <Box>
            <Grid
              alignItems="center"
              container
              justifyContent="center"
              sm={12}
              md={12}
              width="100%"
              className={classes.whatsappContainerMargin}
            >
              <Grid item xs={12} sm={6}>
                <Box>
                  <List className={classes.nopadtpbtm} pl={0}>
                    {listThree.map((each) => (
                      <ListItem
                        alignItems="center"
                        className={classes.nopad}
                        key={each}
                      >
                        <ListItemIcon>
                          <CheckIcon className={classes.listItemIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText}>
                          {each}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <List className={classes.nopadtpbtm} pl={0}>
                    {listFour.map((each) => (
                      <ListItem
                        alignItems="center"
                        className={classes.nopad}
                        key={each}
                      >
                        <ListItemIcon>
                          <CheckIcon className={classes.listItemIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText}>
                          {each}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            alt="Omnichannel Sales CRM Software"
            component={"img"}
            maxWidth="80%"
            maxHeight="80%"
            src={singaporeSmeSalesCRMImg}
            className={classes.singaporeSmeSalesCRMImg}
          />
        </Container>
      </Box>

      {/* Content */}
      <Box textAlign="center" className={classes.pepperCloudCRMHelpsContent}>
        <HeaderTypography component="h2" fontSize={35} fontWeight={600}>
          Unleash the full potential of your business with{" "}
          <span style={{ color: "#15a323" }}>powerful CRM software</span>{" "}
        </HeaderTypography>
        <Box
          py={{ sm: 4, xs: 3 }}
          className={classes.pepperCloudCRMHelpsContent}
        >
          <Container>
            <Box className={classes.pepperCloudCRMHelpsContentListBox}>
              <ul className={classes.pepperCloudCRMHelpsContentList}>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        3.5X
                      </div>
                      <div>Deal closures</div>
                    </div>
                    <div className={classes.yellowImageLine}>
                      <img
                        src={yellowLine}
                        style={{ marginLeft: "47px" }}
                        alt="separator"
                      />
                    </div>
                  </Box>
                </li>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        4.5/5
                      </div>
                      <div>Customer rating</div>
                    </div>
                    <div className={classes.yellowImageLine}>
                      <img
                        src={yellowLine}
                        style={{ marginLeft: "47px" }}
                        alt="separator"
                      />
                    </div>
                  </Box>
                </li>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        50+
                      </div>
                      <div style={{ width: "109%" }}>Small businesses</div>
                    </div>
                    <div className={classes.yellowImageLine}>
                      <img
                        src={yellowLine}
                        style={{ marginLeft: "47px" }}
                        alt="separator"
                      />
                    </div>
                  </Box>
                </li>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        25%
                      </div>
                      <div>Increased revenue</div>
                    </div>
                  </Box>
                </li>
              </ul>
            </Box>
            <Box textAlign="center">
              <Button
                className="book-demo-btn"
                color="secondary"
                size="large"
                variant="contained"
                onClick={executeScroll}
                component="h4"
              >
                Book a free demo
              </Button>
            </Box>
            <Box className={classes.whatsappDisclaimer}>
              *Statistics obtained from multiple sources.
            </Box>
          </Container>
        </Box>
      </Box>

      {/* whatsapp card section */}

      <Box className={classes.accordSection} py={6}>
        <Container>
          {" "}
          <Box maxWidth={820}>
            <HeaderTypography
              className={classes.accordionTitleConvert}
              component="h3"
              display="flex"
              fontSize={40}
              fontWeight={600}
              justifyContent="center"
              lineHeight={1.5}
              mb={0}
              mt={-6}
              pl={1}
              textAlign="left"
            >
              <span fontWeight={600}>
                Accelerate sales growth with{" "}
                <span style={{ color: "#15a323" }}>
                  {" "}
                  <br /> Pepper Cloud CRM
                </span>{" "}
              </span>
            </HeaderTypography>
          </Box>
          <Grid alignItems="center" container justify="center">
            <Grid item md={6} sm={12}>
              <Box
                display="flex"
                justifyContent={{ xs: "center", sm: "center", md: "flex-end" }}
              >
                <Box className={classes.mobilePad} maxWidth={620}>
                  <List>
                    {CustomExperience.map((each) => (
                      <ListItem
                        alignItems="center"
                        className={classes.list}
                        key={each}
                      >
                        <ListItemIcon>
                          <CheckIcon className={classes.listItemIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText}>
                          {each}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                  <Box className={classes.customButtonResponssive} mt={3}>
                    {/* <Button
                      className={classes.customButton}
                      color="secondary"
                      component={Link}
                      size="large"
                      to="/contact"
                      variant="contained"
                    >
                      Get started
                    </Button> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} sm={12}>
              <Box className={classes.buildExperiencesImg} textAlign="right">
                <StaticImage
                  alt="Best CRM Software in Guatemala"
                  className={classes.img}
                  height={"400px"}
                  placeholder="blurred"
                  src="../images/v2/whatsappcrm/south-africa.png"
                />
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Box mb={0} className={classes.faqbackgroundDivder}>
              <Divider light />
            </Box>
            <Container style={{ flexDirection: "column" }}>
              <HeaderTypography
                className={classes.pepperCloudCRMHelps}
                component="h2"
                fontSize={35}
                fontWeight={600}
                my={4}
                textAlign="center"
              >
                <span fontWeight={600}>
                  Best CRM tool with WhatsApp integration <br /> trusted by many
                  clients
                </span>
              </HeaderTypography>
              <br />
              <br />
              <CompanyCarousel className={classes.cimg} />
            </Container>
          </Box>
        </Container>
      </Box>

      {/* clients Sections */}

      {/* <Box>
        <Box mb={0} className={classes.faqbackgroundDivder}>
          <Divider light />
        </Box>
        <Container>
          <HeaderTypography
            className={classes.pepperCloudCRMHelps}
            component="h2"
            fontSize={35}
            fontWeight={600}
            my={4}
            textAlign="center"
          >
            <span fontWeight={600}>
              Best sales <span style={{ color: "#15a323" }}> CRM in Guatemala</span>{" "} <br /> trusted by many
            </span>
          </HeaderTypography>
          <br />
          <br />
          <CompanyCarousel className={classes.cimg} />
        </Container>
      </Box> */}

      <Box mb={8} className={classes.faqbackground}>
        <Box mt={-8} mb={0}>
          <Divider light />
        </Box>
        <Container>
          <section>
            <HeaderTypography
              className={classes.pepperCloudCRMHelps}
              fontSize={35}
              fontWeight={600}
              my={4}
              textAlign="center"
            >
              CRM software - Frequently asked questions
            </HeaderTypography>
            <Card className={classes.faqCard}>
              <Box padding={3}>
                {salesCRMSolution.map((each, index) => (
                  <div key={each.title}>
                    <Box my={2}>
                      <Box
                        alignItems="center"
                        component="h3"
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() =>
                          setActiveCalendar(
                            index === activeCalendar ? -1 : index
                          )
                        }
                      >
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="default"
                        >
                          {index !== activeCalendar ? (
                            <AddIcon fontSize="small" />
                          ) : (
                            <RemoveIcon fontSize="small" />
                          )}
                        </IconButton>
                        <Box
                          component="h3"
                          mt={0}
                          fontSize={18}
                          fontWeight="600"
                          m={0}
                          ml={3}
                        >
                          {each.title}
                        </Box>
                      </Box>
                      <Collapse in={activeCalendar === index}>
                        <ParagraphTypography
                          color="#2e3f4f"
                          fontSize={18}
                          mr={6}
                          mt={2}
                          ml={8.5}
                        >
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                    </Box>
                    <Divider color="#707070" />
                  </div>
                ))}
              </Box>
            </Card>
          </section>
        </Container>
      </Box>

      {/* Book a free demo */}

      <Box className={classes.requestDemoRoot} py={10} textAlign="center">
        <Container>
          <Box alignItems="center" display="flex" justifyContent="center">
            <HeaderTypography
              color="common.white"
              fontSize={35}
              fontWeight={600}
              my={0}
              textAlign="center"
              component="h2"
            >
              Boost your sales with the best CRM software in Guatemala
            </HeaderTypography>
          </Box>
          <Box mt={6} textAlign="center">
            <WhiteButton
              color="primary"
              mb={6}
              onClick={executeScroll}
              size="large"
              variant="contained"
            >
              Book a free demo
            </WhiteButton>
          </Box>
        </Container>
      </Box>

      {/* footer */}
      <Box py={5}>
        <Container>
          <Box textAlign="center">
            <Link to="/">
              <Box
                alt="Best CRM Software in Guatemala"
                component={"img"}
                height="100px"
                src={Logo}
              />
            </Link>
            <ParagraphTypography
              color="#2e3f4f"
              fontFamily="Poppins"
              fontSize={{ sm: 14, xs: 14 }[width] || 20}
              m4={0}
            >
              2da Calle A, 9-63. Zone 10. Zip code 01010. Guatemala City.
              Guatemala.
            </ParagraphTypography>
          </Box>
        </Container>
        <Box
          color="#cbcccd"
          textAlign="end"
          paddingRight="23px"
          className={classes.copyFooter}
        >
          Pepper Cloud - Best CRM Software in Guatemala
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default GoogleAdV3;
